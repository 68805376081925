export const intialState = {
    isLoggedIn: sessionStorage.ehtoken ? true : false,
    beastShieldsList: [],
    beastsList: [],
    monarchsList: [],
    monarchShieldsList: [],
    info: {},
    showMessage: {
        variant: 'success',
        message: 'This is a success message!',
        display: false
    }
}

export function reducer(state = intialState, action) {
    switch (action.type) {
        case "LOG_IN":
            return { ...state, isLoggedIn: action.payload.isLoggedIn };

        case "FETCH_ALL_BEASTS":
            return { ...state, beastsList: action.payload };

        case "FETCH_BEAST_INFO":
            return { ...state, info: action.payload };

        case "BEAST_SHIELDS":
            return { ...state, beastShieldsList: action.payload };

        case "BEAST_SHIELD_INFO":
            return { ...state, info: action.payload };

        case "FETCH_ALL_MONARCHS":
            return { ...state, monarchsList: action.payload };

        case "FETCH_MONARCH_INFO":
            return { ...state, info: action.payload };

        case "MONARCH_SHIELDS":
            return { ...state, monarchShieldsList: action.payload };

        case "MONARCH_SHIELD_INFO":
            return { ...state, info: action.payload };

        default:
            return state;
    }
}