// importi react hooks
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// import material-ui components
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';


// import context, actions & reducers
import { validateEmail } from '../../utils';
import { sendResetPwdRequest } from '../../Actions/resetPwdAction';

export default function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();

  const [values, setValues] = useState({
    email: '',
    errorEmail: '',
    showMessage: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value, errorEmail: '' });
  };

  function handleCancel() {
    setValues({ ...values, errorEmail: '', email: '' });
    history.push('/');
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validateEmail(values.email)) {
      sendResetPwdRequest({ email: values.email }, history);
    } else {
      setValues({ ...values, errorEmail: ' * Invalid' });
    }
  }

  return (
    <div className={classes.card}>
      <label className={classes.title}>Enter registered email id to reset password</label>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth className={clsx(classes.textField)} variant="outlined">
          <InputLabel htmlFor="email">Email Id<span className="error">{values.errorEmail}</span></InputLabel>
          <OutlinedInput
            type="email"
            id="email"
            value={values.email}
            onChange={handleChange('email')}
            labelWidth={values.errorEmail ? 120 : 60}
          />
        </FormControl>

        <div className={classes.button}>
          <Button type="submit" variant="contained" color="secondary" onClick={handleSubmit}>Submit</Button>
          <Button type="submit" variant="contained" color="primary" onClick={handleCancel}>Cancel</Button>
        </div>
      </form>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(1),
    width: '100%',
    backgroundColor: 'whitesmoke'
  },
  card: {
    alignContent: "center",
    padding: "2%",
    minWidth: 275,
    margin: "20% 35%",
    backgroundColor: "lavender"
  },
  title: {
    fontSize: 16,
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    // marginLeft: theme.spacing(2),
  },
  link: {
    marginRight: theme.spacing(2)
  },
}));