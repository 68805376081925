// import react hooks
import React, { Fragment, Suspense } from 'react';
import Routes from '../routes';

export default function App() {
  return (
    <Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes />
      </Suspense>
    </Fragment>
  )
}
