// importi react hooks
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// import material-ui components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// import context, actions & reducers
import { EnglandHistoryContext } from '../Store';
import { adminLogout } from '../Actions/loginAction';

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useContext(EnglandHistoryContext);

  function handleLogin() {
    history.push("/");
  }

  function handleLogout() {
    adminLogout(dispatch);
    history.push("/dashboard");
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.header}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <img className="logo" src="/images/logo.png" alt="logo" />
          </Typography>
          {
            state.isLoggedIn ?
              <Button color="inherit" onClick={handleLogout}>Logout</Button> :
              <Button color="inherit" onClick={handleLogin}>Login</Button>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "red"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: 'darkmagenta'
  }
}));