// import react hooks
import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

// import material-ui components
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

// import context, actions & reducers
import { adminLogin } from '../../Actions/loginAction';
import { EnglandHistoryContext } from '../../Store';
import { validateEmail } from '../../utils';

export default function Login() {
  const classes = useStyles();
  const { state, dispatch } = useContext(EnglandHistoryContext);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    errorEmail: '',
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value, errorEmail: '' });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  function submitLoginForm(e) {
    e.preventDefault();
    if (validateEmail(values.email)) {
      const payload = {
        "email": values.email,
        "password": values.password
      }
      adminLogin(payload, dispatch);
    } else {
      setValues({ ...values, errorEmail: ' * Invalid' });
    }
  }

  if (state.isLoggedIn) return <Redirect to="/dashboard/beasts" />

  return (
    <div className={classes.card}>
      <label className={classes.title}>Sign In to your account</label>
      <form onSubmit={submitLoginForm}>
        <FormControl fullWidth className={clsx(classes.textField)} variant="outlined">
          <InputLabel htmlFor="email">Email Id<span className="error">{values.errorEmail}</span></InputLabel>
          <OutlinedInput
            type="email"
            id="email"
            value={values.email}
            onChange={handleChange('email')}
            labelWidth={values.errorEmail ? 120 : 60}
          />
        </FormControl>
        <FormControl className={clsx(classes.textField)} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        <div className={classes.button}>
          <Button type="submit" variant="contained" color="secondary" onClick={submitLoginForm}>Login</Button>
          <Link to="/forgotpassword" className={classes.link}>Forgot Password ?</Link>
        </div>
      </form>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(1),
    width: '100%',
    backgroundColor: 'whitesmoke'
  },
  card: {
    alignContent: "center",
    padding: "2%",
    minWidth: 275,
    margin: "20% 35%",
    backgroundColor: "lavender"
  },
  title: {
    fontSize: 16,
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(2),
  },
  link: {
    marginRight: theme.spacing(2)
  },
}));