import { toasterMessage } from '../utils';
const url = process.env.REACT_APP_API_URL;

export const adminLogin = async (payload, dispatch) => {
  sessionStorage.removeItem("ehtoken");
  try {
    const response = await fetch(`${url}/login`, {
      method: 'POST',
      body: JSON.stringify(payload), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const jsonData = await response.json();

    if (response.status === 401) toasterMessage('error', 'Wrong password');

    if (response && response.status === 200 && jsonData.message === "Successful login") {
      sessionStorage.setItem("ehtoken", `Bearer ${jsonData.loginToken}`);
      return dispatch({
        type: "LOG_IN",
        payload: {
          isLoggedIn: true
        }
      });
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export const adminLogout = async (dispatch) => {
  sessionStorage.removeItem("ehtoken");
  return dispatch({
    type: "LOG_IN",
    payload: {
      isLoggedIn: false
    }
  });
};