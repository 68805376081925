import React, { Suspense, Fragment } from 'react';
import { render } from 'react-dom';
import App from './Components/App';
import StoreProvider from './Store';
import * as serviceWorker from "./serviceWorker";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

render(
   <Fragment>
      <Suspense fallback={<div>Loading...</div>}>
         <StoreProvider>
            <App />
         </StoreProvider>
      </Suspense>
   </Fragment>
   , document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();