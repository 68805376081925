import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 3000,
  draggable: false,
})
export function toasterMessage(type, message) {
  toast[type](message)
}

// The email validation part contains the following ASCII characters.

// Uppercase (A-Z) and lowercase (a-z) English letters.
// Digits (0-9).
// Characters ! # $ % & ' * + - / = ? ^ _ ` { | } ~
// Character . ( period, dot or fullstop) provided that it is not the first or last character and it will not come one after the other.
// The domain name [for example com, org, net, in, us, info] part contains letters, digits, hyphens, and dots.

export function validateEmail(mail) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return (true)
  }
  return (false)
}


// To check a password between 6 to 16 characters which contain 
// only characters, numeric digits, underscore and first character must be a letter

export function validatePassword(pwd) {
  console.log(pwd.length)
  if (pwd.length > 5 && pwd.length < 16) {
    return true;
  }
  return false;
}
