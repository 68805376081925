// importi react hooks
import React, { createContext, useReducer } from 'react';

// import context, actions & reducers
import { reducer, intialState } from './Reducers/reducer';

export const EnglandHistoryContext = createContext("");

export default function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, intialState);
  const value = { state, dispatch };

  return (
    <EnglandHistoryContext.Provider value={value}>
      {props.children}
    </EnglandHistoryContext.Provider>
  );
}
