import { toasterMessage } from '../utils';
const url = process.env.REACT_APP_API_URL;

export const sendResetPwdRequest = async (payload, history) => {
  sessionStorage.removeItem("ehtoken");
  try {
    const response = await fetch(`${url}/forgetPassword`, {
      method: 'POST',
      body: JSON.stringify(payload), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response && response.status === 200) {
      toasterMessage('success', "Reset password link has been sent to your email.");
      history.push('/');
    } else {
      toasterMessage('error', "Email not registered.");
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export const dispatchNewPassword = async (token, payload, history) => {
  sessionStorage.removeItem('ehtoken');
  try {
    const response = await fetch(`${url}/resetPassword/${token}`, {
      method: 'POST',
      body: JSON.stringify(payload), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response && response.status === 200) {
      toasterMessage('success', "Password changed successfully.");
      history.push('/');
    }

    if (response && response.status === 401) {
      toasterMessage('error', "This link is exipred.");
      history.push('/');
    }
  } catch (error) {
    console.error('Error:', error);
  }
}