// import react hooks
import React, { useState, useContext, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// import context, actions & reducers
import { EnglandHistoryContext } from '../../Store';

// import material-ui components
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import SecurityIcon from '@material-ui/icons/Security';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 240;
function ResponsiveDrawer(props) {
  const { state } = useContext(EnglandHistoryContext);
  const history = useHistory();
  const params = useLocation().pathname;
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <h3 className={classes.logoTitle}>England History</h3>
      </div>
      <Divider />
      <List>
        {['Beasts', 'Monarchs'].map((text, index) => (
          <Fragment key={text}>
            <ListItem button onClick={() => history.push(`/dashboard/${text.toLowerCase()}`)}>
              <ListItemIcon >{<FolderIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
            <ListItem className={classes.secItem} button onClick={() => history.push(`/dashboard/${text.toLowerCase()}/shields`)}>
              <ListItemIcon className={classes.icon}>{<SecurityIcon />}</ListItemIcon>
              <ListItemText primary='Shields' />
            </ListItem>
            <Divider component="li" variant="inset" />
          </Fragment>
        ))}
      </List>
    </div>
  );

  if (!state.isLoggedIn) return <Redirect from="/dashboard" to="/" />;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>{params.slice(11)}</Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>

      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: "55px",
    backgroundColor: "#ffffff",
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      marginTop: "64px"
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: "64px"
  },
  content: {
    marginTop: theme.spacing(4),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    textTransform: "capitalize",
  },
  logoTitle: {
    marginLeft: "20px",
    paddingTop: "15px"
  },
  secItem: {
    marginLeft: '20px',
  },
  icon: {
    minWidth: '40px'
  }
}));

export default ResponsiveDrawer;