// importi react hooks
import React, { useContext, lazy, Suspense } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";

// import context, actions & reducers
import { EnglandHistoryContext } from './Store';

// import components
import LoginForm from './Components/Login/Login';
import ForgotPassword from './Components/UpdatePassword/ForgotPassword';
import UpdatePassword from './Components/UpdatePassword/UpdatePassword';
import Dashboard from './Components/Dashboard/Dashboard';
import Header from './Components/Header';
import Backdrop from './Components/Backdrop';

const Beasts = lazy(() => import('./Components/Beasts/Beasts'));
const Beasts_Shields = lazy(() => import('./Components/Beasts/Shields'));
const Monarchs = lazy(() => import('./Components/Monarchs/Monarchs'));
const Monarchs_Shields = lazy(() => import('./Components/Monarchs/Shields'));

export default function Routes() {
  return (
    <Router>
      <Header />
      <Switch>
        <PrivateRoute path="/dashboard">
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Suspense fallback={<Backdrop />}>
            <PrivateRoute exact path="/dashboard/beasts" component={Beasts} />
            <PrivateRoute exact path="/dashboard/beasts/shields" component={Beasts_Shields} />
            <PrivateRoute exact path="/dashboard/Monarchs" component={Monarchs} />
            <PrivateRoute exact path="/dashboard/Monarchs/shields" component={Monarchs_Shields} />
          </Suspense>
        </PrivateRoute>
        <Route exact path="/forgetPassword/:token" component={UpdatePassword} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/" component={LoginForm} />
      </Switch>
    </Router>
  )
}


export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(EnglandHistoryContext);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest}
      render={props => (state.isLoggedIn ? <Component {...props} /> : <Redirect to="/" />)} />
  );
};
