// importi react hooks
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// import material-ui components
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

// import context, actions & reducers
import { validatePassword } from '../../utils';
import { dispatchNewPassword } from '../../Actions/resetPwdAction';

function getSteps() {
  return ['New password', 'Confirm password'];
}

function getStepContent(step, classes, values, handleChange, handleClickShowPassword, handleMouseDownPassword) {
  switch (step) {
    case 0:
      return <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>;
    case 1:
      return <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.confirmPassword}
          onChange={handleChange('confirmPassword')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>;
    default:
      return 'Unknown step';
  }
}

export default function UpdatePassword() {
  const classes = useStyles();
  const history = useHistory();
  const token = useParams().token;
  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
    errorPassword: ''
  });
  const steps = getSteps();

  const handleNext = () => {
    if (validatePassword(values.password) && !values.confirmPassword) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    if (validatePassword(values.password) && values.password === values.confirmPassword) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      dispatchNewPassword(token, { password: values.password }, history);
    }

    if (!validatePassword(values.password)) {
      setValues({ ...values, errorPassword: '* password should 6 to 16 characters long' });
    }

    if (validatePassword(values.password) && values.confirmPassword && values.password !== values.confirmPassword) {
      setValues({ ...values, errorPassword: '* password doesn\'t matched' });
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value, errorPassword: '' });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}<span className="error">{values.errorPassword}</span></StepLabel>
              <StepContent>
                {getStepContent(index, classes, values, handleChange, handleMouseDownPassword, handleClickShowPassword)}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                  </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Card>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  card: {
    minWidth: 275,
    margin: "20% 35%",
    paddingRight: '15px',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '100%',
    marginRight: '20px',
  },
}));